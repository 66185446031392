import { Link } from "gatsby"
import React from "react"
import ThemeToggle from "./themeToggle"
import { NavLink, NavExtLink, BlobHeader } from "./atoms"

const Header = ({ activePage }) => (
  <header className="container m-auto px-5 sm:px-12 md:px-20 max-w-screen-xl h-32 overflow-hidden">
    <nav
      className="mt-auto h-full flex space-x-6 items-center justify-center md:justify-start text-sm"
      aria-label="Main Navigation"
    >
      <Link to="/" aria-label="Website logo, go back to homepage.">
        <svg
          aria-hidden="true"
          role="img"
          className="h-12 sm:h-10 w-12 sm:w-10 fill-current hover:text-accent transition duration-150"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 382.6 524.3"
        >
          <path
            d="M382.6,258.5v180.8v85H0V513V60.6C0,40.4,0,20.2,0,0c28.1,0,56.3,0,84.4,0h100.2c45.3,0,90.5,0,135.8,0
	c0,20.7,0,41.4,0,62.1c-86.3,0-172.5,0-258.8,0C61.8,196,61.9,330,62,463.9c85.9,0.2,171.7,0.5,257.6,0.7
	c0.3-77,0.6-153.9,0.9-230.9c-34.3,0-68.7,0-103,0c0-20.1,0-40.2,0-60.2h165.1C382.6,201.8,382.6,230.1,382.6,258.5z"
          />
          <g>
            <path
              style={{ fill: "#34D399" }}
              d="M382.6,364.8v60.6H108.9V0H171c-0.1,121.6-0.2,243.2-0.4,364.8H382.6z"
            />
          </g>
        </svg>
      </Link>
      <span className="hidden sm:flex flex-grow items-center space-x-6">
        <NavLink to="/" title="Home" selected={activePage === "/"}>
          Home
        </NavLink>
  {/* <NavLink to="/blog" title="Blog" selected={activePage === "blog"}>
          Blog
        </NavLink> */}
        <NavLink to="/about" title="About" selected={activePage === "about"}>
          About
        </NavLink>
        <NavExtLink to="https://codestats.net/users/melchsee" title="Stats">
          Stats
        </NavExtLink>
      </span>
      <ThemeToggle className="hidden sm:block hover:text-accent transition duration-150" />
    </nav>

    <div className="hidden sm:block">
      <div className="-mt-120 sm:-mt-120 ml-4">
        <BlobHeader />
      </div>
    </div>
  </header>
)

export default Header
